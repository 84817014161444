body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Centering the loader */
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Job card hover effect */
.job {
  transition: transform 0.2s;
}

.job:hover {
  transform: translateY(-5px);
}

/* Styling for the search bar */
.search-bar input {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

/* Ensure buttons don't break text */
button {
  white-space: nowrap;
}

/* Pagination styling */
.pagination button {
  transition: background-color 0.2s, color 0.2s;
}


