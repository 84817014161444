.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80vh;
    font-weight: bold;
    color:rgb(55, 65, 81)
  }
  .loading-container-model {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 40vh;
    font-weight: bold;
    color:rgb(55, 65, 81)
  }
  
  .loading-spinner {
    border: 9px solid rgba(0, 0, 0, 0.1);
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s ease infinite;
    position: absolute;
   
  }

  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  