




#weevaluate-sdk .search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    margin: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

#weevaluate-sdk .search-bar input,
#weevaluate-sdk .search-bar select {
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    flex: 1;
}

#weevaluate-sdk .search-bar button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

#weevaluate-sdk .job-listing {
    margin: 20px;
}

#weevaluate-sdk .job {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#weevaluate-sdk .job-info h2 {
    margin: 0 0 10px 0;
    font-size: 20px;
}

#weevaluate-sdk .tags span {
    background-color: #eee;
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 12px;
}

#weevaluate-sdk .job-salary span {
    font-size: 16px;
    font-weight: bold;
}

#weevaluate-sdk .job-salary button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 20px;
}

#weevaluate-sdk .emailverify {
        margin: 0 0 10px 0;
        font-size: 20px;
        text-align: center;
    

}
